<template>
  <div class="page-content" id="details" v-loading="isLoading">
    <!-- 主要内容 -->
    <div class="main detail-content-box">
      <div class="good-info">
        <!-- 左侧商品轮播图 -->
        <div class="carousel-box">
          <img
            ref="bannerHeight"
            class="good-detail-img"
            :src="productDetails?.goods?.goodsImg"
            alt="商品图1"
            @load="imgload"
          />
        </div>
        <!-- 左侧商品轮播图END -->

        <!-- 右侧内容区 -->
        <div class="content">
          <div class="good-title">
            <el-tag class="good-title-tag"
              >此订单剩余时间:{{ remainingTime }}天</el-tag
            >
          </div>
          <div class="good-tit">{{ productDetails?.goods?.goodsName }}</div>
          <p class="intro">{{ productDetails?.goods?.goodsIntroduction }}</p>
          <div class="subItem">
            <div class="itemTitle">商品权限</div>
            <div class="itemContent">
              <ul>
                <li
                  v-for="(res, resIndex) in rzJyGoodsList"
                  :key="resIndex"
                  @click="selectItem(res, resIndex)"
                  :class="{ selectActive: nexNumber === resIndex }"
                >
                  <span></span>
                  <el-badge class="badgeItem">{{
                    res.goods.goodsName
                  }}</el-badge>
                </li>
              </ul>
            </div>
          </div>
          <div class="subItem" style="align-items: self-end">
            <div class="itemTitle">按年</div>
            <div class="price-infos">
              <div class="sale-prices">
                <div class="price-icons">¥{{ productDetails?.goods?.basePrice }}/年(约¥{{productDetails?.dailyPrice}}/天)</div>
              </div>
              <!-- <del class="price-old">¥{{ originalPrice }}</del> -->
            </div>
          </div>
          <div class="subItem" style="align-items: self-end">
            <div class="itemTitle">价格</div>
            <div class="price-info">
              <div class="sale-price">
                <div class="price-icon">¥</div>
                <div class="price-val">{{ productDetails?.payment }}</div>
              </div>
              <!-- <del class="price-old">¥{{ originalPrice }}</del> -->
            </div>
          </div>
          <!-- 内容区底部按钮 -->
          <div>
            <el-button
              class="shop-cart"
              :diabled="true"
              :disabled="payBtnDisabled"
              @click="handleShop"
              >立即订购</el-button
            >
          </div>
        </div>
      </div>
      <!-- 右侧内容区END -->
      <div class="good-info-item">
        <div class="good-item-list">
          <div class="good-item-tit">
            <span class="good-item-line"></span>商品详情
          </div>
          <div class="goodimg-box">
            <p v-html="productDetails?.goods?.goodsDetails"></p>
          </div>
        </div>
      </div>
    </div>
    <!-- 主要内容END -->
    <!-- 微信支付二维码 -->
    <el-dialog
      :visible.sync="codeDialogVisible"
      :close-on-click-modal="false"
      @close="closeDialog"
      width="350px"
      center
      title="使用微信扫码支付"
    >
      <qriously :value="codeUrl" :size="300" />
      <!-- <img src="../assets/img/code.png" alt="" style="width:100%"><br> -->
    </el-dialog>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { getresetDetails, createResetOrder } from '@/api/home'
// login
export default {
  data() {
    return {
      bannerHeight: null,
      showSeckill: '', //控制秒杀按钮展示
      isLoading: true, //控制加载层
      se: '', //控制选中颜色改变
      nexNumber: 0,
      yearNumber: '0',
      show: false,
      showIndex: null,
      payPrice: '', //售卖价
      originalPrice: '', //原价
      seckillPrice: '', //秒杀价格
      seckillId: '', //秒杀id
      preferentialPrice: '', //优惠
      dis: false, // 控制“加入购物车按钮是否可用”
      payBtnDisabled: false, //控制立即购买按钮重复提交
      payBtnDisabledSeckill: false,
      codeDialogVisible: false, //微信支付二维码弹窗
      // codeDialogVisibleHint: false, //提示信息弹窗
      productID: '', // 商品id
      productDetails: {}, // 商品详细信息
      productPicture: '', // 商品图片
      timer: null, // 定时器
      codeUrl: '', //支付二维码地址
      orderId: '', //订单号
      particulars: '',
      rzJyGoodsList: [],
      remainingTime: 0,
      spuSaleAttrList: [
        { label: '小程序', value: '1' },
        { label: '公众号', value: '2' },
      ],
      payOrder: {
        productId: '', //商品id
        payType: 'wxpay', //支付方式
        timeType: '',
        timeLimit: '',
        goodsId: '',
      },
      spuSaleAttrYearList: [],
      selectArr: [], // 存放被选中的值
      subIndex: [], //是否选中 因为不确定是多规格还是但规格，所以这里定义数组来判断
    }
  },
  // 通过路由获取商品id
  activated() {
    if (this.$route.query.productID != undefined) {
      this.productID = this.$route.query.productID
    }
  },
  computed: {
    userInfo: {
      get() {
        return this.$store.state.user.user
      },
      set(value) {
        this.$store.commit('setUser', value)
      },
    },
  },
  watch: {
    // 监听商品id的变化，请求后端获取商品数据
    // productID: function (val) {
    //   this.getDetails(val);
    // },
    // 监听路由变化
    $route: {
      immediate: true,
      handler(to) {
        // console.log(to);
        if (to.name == 'resetDetails') {
          this.getresetDetails(to.query.productID)
        }
      },
    },
  },
  mounted() {
    // this.loging()
    this.imgload()
    // this.updateUserInfo()
    // this.getresetDetails()
    window.addEventListener(
      'resize',
      () => {
        this.bannerHeight = this.$refs.bannerHeight
          ? this.$refs.bannerHeight[0].height > 560
            ? 410
            : this.$refs.bannerHeight[0].height
          : 410
        const detailsContainer = document.querySelector(
          '.el-carousel__container'
        )
        if (detailsContainer) {
          detailsContainer.style.height = this.bannerHeight + 'px'
          this.imgload()
        }
      },
      false
    )
  },
  methods: {
    imgload() {
      this.$nextTick(() => {
        if (this.$refs.bannerHeight) {
          this.bannerHeight = this.$refs.bannerHeight[0]
            ? this.$refs.bannerHeight[0].height > 560
              ? 410
              : this.$refs.bannerHeight[0].height
            : 410
        }
      })
    },
    ...mapActions(['setUser', 'unshiftShoppingCart', 'addShoppingCartNum']),
    // 获取商品详细信息
    getresetDetails() {
      this.isLoading = true
      const mobile = this.$route.query.phone || this.userInfo.mobile 
      const orderId = this.$route.query.orderId || ''
      const parmas = {
        phoneNumber: mobile,
        orderId: orderId,
      }
      getresetDetails(parmas)
        .then((res) => {
          if (res.code === 0) {
            this.rzJyGoodsList = res.data.rzJyGoodsList
            this.productDetails = this.rzJyGoodsList[0]
            this.remainingTime = res.data.rzJyGoodsList[0].remainingDays
            this.selectItem(this.productDetails, 0)
          }
        })
        .catch((err) => {
          this.$message.error(err.data.msg)
          this.$router.push('/')
        })
    },
    /**
     * 修复富文本图片宽度
     * @description 解决图片宽高超出显示不全问题(让其自适应)
     * @param {String} html - 富文本
     * @return String
     */
    repairRichText(html) {
      // console.log(html);
      // 去掉<img>中style /width / height属性
      let newContent = html.replace(/<img[^>]*>/gi, (match) => {
        match = match
          .replace(/style="[^"]+"/gi, '')
          .replace(/style='[^']+'/gi, '')
        match = match
          .replace(/width="[^"]+"/gi, '')
          .replace(/width='[^']+'/gi, '')
        match = match
          .replace(/height="[^"]+"/gi, '')
          .replace(/height='[^']+'/gi, '')
        return match
      })
      // 修改所有style里的width属性为max-width:100%
      newContent = newContent.replace(/style="[^"]+"/gi, (match) => {
        match = match
          .replace(/width:[^;]+;/gi, 'max-width:100%;')
          .replace(/width:[^;]+;/gi, 'max-width:100%;')
        return match
      })
      // 去掉所有<br/>标签
      newContent = newContent.replace(/<br[^>]*\/>/gi, '')
      // img标签添加style属性：max-width:100%;height:auto
      newContent = newContent.replace(
        /<img/gi,
        '<img style="max-width:100%;height:auto;display:block;margin-top:0;margin-bottom:0;"'
      )
      // return
      return newContent
    },
    //选择支付方式
    selectPayType(type) {
      // console.log("支付方式：" + type);
      this.payOrder.payType = type
      //this.$router.push({ path: '/order' })
    },
    isWX() {
      var wx = window.navigator.userAgent.toLowerCase()
      console.log(wx)
      if (wx.match(/MicroMessenger/i) == 'micromessenger') {
        return true
      } else {
        return false
      }
    },
    // 立即购买
    async handleShop() {
      if (!this.$store.getters.getUser) {
        this.$store.dispatch('setShowLogin', true)
        return
      }
      this.payBtnDisabled = true
      const params = {
        goodsId: this.productDetails.goods.id,
        goodsName: this.productDetails.goods.goodsName,
        payPrice: this.productDetails.payment,
        extensionOrderId: this.productDetails.orderId,
        expirationTime: this.productDetails.expirationTime,
        phoneNumber: this.userInfo.mobile,
        // mobilePay: this.$device.mobile ? 1 : 0
        mobilePay: this.isWX() == true ? 2 : this.$device.mobile ? 1 : 0,
      }
      const res = await createResetOrder(params)
      if (res.data.map.orderInfo.orderId) {
        this.payBtnDisabled = false
        this.$router.push({
          name: 'ConfirmOrder',
          query: {
            orderId: res.data.map.orderInfo.orderId,
            productType: res.data.map.orderInfo.productType,
            orderType: res.data.map.orderType,
          },
        })
      } else {
        this.payBtnDisabled = false
      }
    },
    //关闭微信支付二维码对话框时让“确认支付”按钮可用
    closeDialog() {
      // console.log("close.................");
      this.payBtnDisabled = false
      // console.log("清除定时器");
      clearInterval(this.timer)
    },
    // 加入购物车
    addShoppingCart() {
      // 判断是否登录,没有登录则显示登录组件
      if (!this.$store.getters.getUser) {
        this.$store.dispatch('setShowLogin', true)
        return
      }
      this.$axios
        .post('/api/user/shoppingCart/addShoppingCart', {
          user_id: this.$store.getters.getUser.user_id,
          product_id: this.productID,
        })
        .then((res) => {
          switch (res.data.code) {
            case '001':
              // 新加入购物车成功
              this.unshiftShoppingCart(res.data.shoppingCartData[0])
              this.notifySucceed(res.data.msg)
              break
            case '002':
              // 该商品已经在购物车，数量+1
              this.addShoppingCartNum(this.productID)
              this.notifySucceed(res.data.msg)
              break
            case '003':
              // 商品数量达到限购数量
              this.dis = true
              this.notifyError(res.data.msg)
              break
            default:
              this.notifyError(res.data.msg)
          }
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    addCollect() {
      // 判断是否登录,没有登录则显示登录组件
      if (!this.$store.getters.getUser) {
        this.$store.dispatch('setShowLogin', true)
        return
      }
      this.$axios
        .post('/api/user/collect/addCollect', {
          user_id: this.$store.getters.getUser.user_id,
          product_id: this.productID,
        })
        .then((res) => {
          if (res.data.code == '001') {
            // 添加收藏成功
            this.notifySucceed(res.data.msg)
          } else {
            // 添加收藏失败
            this.notifyError(res.data.msg)
          }
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },

    // 选择类别
    selectSpec() {
      let t = this
      t.showSpec = true
    },
    selectItem(res, index) {
      console.log('index', index)
      this.isLoading = true
      if (index === index) {
        this.productDetails = this.rzJyGoodsList[index]
        this.nexNumber = index
      } else if (index === index) {
        this.productDetails = this.rzJyGoodsList[index]
        this.nexNumber = index
      } else if (index === index) {
        this.productDetails = this.rzJyGoodsList[index]
        this.nexNumber = index
      }
      // setTimeout(() => {
        this.isLoading = false // 加载完成
      // }, 300)
      // console.log(resIndex);
      // console.log("进来了");
    },
    checkItem: function () {
      var self = this
      var option = self.subItemList
      var result = [] // 定义数组存储被选中的值
      // console.log(JSON.parse(JSON.stringify(self.selectArr)));
      for (let i in option) {
        result[i] = self.selectArr[i] ? self.selectArr[i] : ''
      }
      for (let i in option) {
        var last = result[i] // 把选中的值存放到字符串last去
        for (let k in option[i].item) {
          result[i] = option[i].item[k].name // 赋值，存在直接覆盖，不存在往里面添加name值
          // console.log("这里:", JSON.parse(JSON.stringify(result)));
        }
        result[i] = last // 还原，目的是记录点下去那个值，避免下一次执行循环时避免被覆盖
      }
      self.$forceUpdate() // 重绘
      self.showSelectSpec = self.selectArr.join('、')
      // console.log(self.showSelectSpec);
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/common.scss';
.page-content {
  margin-top: 10px;
}
.detail-content-box {
  width: 100%;
  min-height: 64vh;
  margin: 0 auto;
  .good-info {
    display: flex;
    background-color: #fff;
    padding: 40px;
    margin-bottom: 36px;
    margin-top: 40px;
    .carousel-box {
      // width: 45%;
      width: 38.5%;
      // height: 500px;
      // height: auto;
      height: 483px;
      margin-bottom: 40px;
      .good-detail-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .content {
      margin-left: 70px;
      width: 55%;
      position: relative;
      .good-title {
        text-align: right;
        .good-title-tag {
          font-size: 18px;
        }
      }
      .good-tit {
        font-size: 35px;
        color: #333;
        font-weight: bold;
        margin-bottom: 5px;
      }
      .intro {
        color: #666;
        padding-top: 10px;
        margin-bottom: 30px;
      }
      .subItem {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        // background-color: red;
        .itemTitle {
          width: 15%;
          font-size: 16px;
          color: #666;
        }
        .itemContent {
          width: 80%;
        }

        ul {
          display: flex;
          align-items: s;
          flex-wrap: wrap;
          width: 100%;
          li {
            font-size: 18px;
            padding: 5px 20px;
            border-radius: 5px;
            border: 1px solid #e7ecf0;
            box-sizing: border-box;
            margin-right: 20px;
            margin-bottom: 20px;
          }
          li:hover {
            cursor: pointer;
            border: 1px solid #fa8919 !important;
            color: #fa8919;
          }
          .selectActive {
            border: 1px solid #fa8919 !important;
            color: #fa8919;
          }
        }
        // 选择支付方式
        .zhiFuItem {
          display: flex;
          /* 选择支付方式 */
          .change-paytype {
            position: relative;
            display: flex;
            align-items: center;
            width: 207px;
            height: 58px;
            margin-right: 10px;
            border: 1px solid #e7ecf0;
            border-radius: 4px;
            cursor: pointer;
          }

          .change-paytype.current {
            border-color: #fa8919;
            color: #fa8919;
          }

          .change-paytype.current:after {
            content: '';
            display: block;
            position: absolute;
            right: -1px;
            bottom: -1px;
            width: 28px;
            height: 28px;
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAOKADAAQAAAABAAAAOAAAAAANV2hTAAADnUlEQVRoBd3ZzUsUYRwH8O/zzK6llaZkm5mkWdEhN4KEgqKkDhpU9KJpNy/hKch/QK9BdRM7SAQeSnujDgUWXqKgyBBqISKDlswwETXzbZ15eubRGbZ1dp3dmdndmQd255nnZef34Tf7G2UBD7fIta3txKs+FcfA2jwJ1HBq8jwHjMZ5DhiL8xTQCOcZYDycJ4CJcK4HroZzNdAMzrVAszhXApPBuQ6YLM5VwFRwrgGminMF0Aou64FWcVkNtAOXtUC7cFkJtBOXdUC7cVkFdAKXNUCncFkBdBKXcaDTuIwC04HLGDBduIwA04lLOzDduLQCM4FLGzBTuLQAM4lTgZL65lRzGkerWyCduQX41oL9eGvIcOzXJcdxNe2Qqi8LFPv7G4sd+wyBPsNRi4OO4giFVHsdtKpRj1IZ7Nb7sR3bM+gojvohne4E3X1Sd8ivb0Dhr3jNVqCjOH8upLO3QcuPCgtjDEp/G5SBrng2MW4b0BRuTQEwP5kwIMNJvk+60A1aekBMM0WG/LwVLHTfcHn0oC1V1AxOqrsJ6VQnSH4p2NcX0TEk7q8rhu9iL2jJUhFhi/OQn7aAfX6SeN/yrOUiYwanXotsPwxCCEiwiZ9QkQGAJQ4yfxvH9YAUVoh1bGEG8uNmsO+vEu+LmrWUQbM49Xpsbgp0V624NAnsBdlQwjPZFxVKTLdoJ3xND0EKysQEm5uA3NsU93kXs1s/TRmYDE5cbTQEzIyDVh4XpyRQBbI+ADa08nYlgSB8jb18frNYy6ZHsXivHhj9qAdutpMSMGnccjTs1yAwNwm6o0aMkC1BIK8Y7NtLPV5SdhBS/V2Q3I1ijE2EOe48MD6kr0mmkzQwVZwWFBv5wCvpH9CKY2JIFI/cIo7sB6k8AencHZCcPDHHxr4s4aaGte1JH5MCWsVp0bGfA0BkRn+m0ZL9IPxFD10BkXLEMmVkEHJPA7+tx7RtKR1NA+3CaVGy4fcAL/m0/IgYUisl4dVVbUr4DeQHl3imp8S5lTdTQLtxWsBs+B0gR0D5I0RrCq+s8qNmjp/VhiwdVwU6hdOiFv/mRGZBNu2B8qkHyrOrPIURbdryMeGfak7jLEe/ygdw3PTSTW+w0O04lcQIwoZAL+BUIGG0b8Ut6hkc/yb7/P7gfxn0Ck5kj5BO0hoO6UCP4fql8sJWFSqAXsHx75tCCenwlRfWkYbQgsik23Hqo0CtlmpB8fmlLvW2VGFa+wcOncY5YWRXPQAAAABJRU5ErkJggg==)
              no-repeat;
            background-size: 28px 28px;
          }

          .change-paytype-icon {
            margin: 0 11px 0 15px;
          }

          .paytype-icon {
            display: block;
            width: 24px;
            height: 24px;
          }

          .change-paytype-info {
            position: relative;
            flex: 1;
          }
          .change-paytype:hover {
            border-color: #fa8919;
          }
          .change-paytype-label:hover {
            color: #fa8919;
          }
        }
        .price-infos {
          width: 80%;
          display: flex;
          align-items: self-end;
          flex-wrap: wrap;
          color: #fa8919;
          margin-top: 20px;
          
        .price-icons {
            font-size: 0.9375rem;
            border: 1px solid #fa8919 !important;
            color: #fa8919;
            padding: 5px;
            border-radius: 5px;
          }
        }
        // 价格
        .price-info {
          width: 80%;
          display: flex;
          align-items: self-end;
          flex-wrap: wrap;
          color: #ff4302;
          margin-top: 20px;
          .sale-price {
            display: flex;
            align-items: self-end;
          }
          .sale-prices {
            display: flex;
            align-items: self-end;
            border: 1px solid #fa8919 !important;
            padding: 5px;
            border-radius: 8px;
          }
          .price-icon {
            font-size: 30px;
            font-weight: 400;
            margin-right: 10px;
          }
          .price-val {
            font-size: 45px;
            font-weight: bold;
          }
          .price-old {
            color: #a1a1a1;
            font-size: 25px;
            margin-left: 20px;
          }
        }
      }
      .shop-cart {
        padding: 0 30px;
        height: 60px;
        color: #fff;
        font-size: 30px;
        background-color: #fa8919;
        margin-top: 35px;
        position: absolute;
        bottom: 20px;
      }
      .seckill-cart {
        // position: absolute;
        // bottom: 20px;
        // left: 200px;
        // width: 180px;
        // height: 3rem;
        // line-height: 24px;
        // border: 1px solid red;
        // border-radius: 0.20833rem;
        // margin: 0 auto;
        // display: inline-block;

        // padding: 0 10px;
        width: 180px;
        height: 3rem;
        color: #fff;
        border: 1px solid red;
        margin-top: 35px;
        line-height: 24px;
        position: absolute;
        bottom: 20px;
        display: inline-block;

        .seckill-price {
          position: relative;
          float: left;
          width: 90px;
          height: 100%;
          background-color: red;
          text-align: center;
          line-height: 3.125rem;
          color: #fff;
          font-weight: 700;
          margin-right: 8px;
          i {
            position: absolute;
            right: 0;
            top: 0;
            width: 0;
            height: 0;
            border-color: transparent #fff transparent transparent;
            border-style: solid;
            border-width: 24px 10px 0 0;
          }
        }
        .origin {
          font-size: 14px;
          font-weight: 700;
          padding-left: 5px;
          color: gray;
          line-height: 3.125rem;
        }
      }
      :hover {
        cursor: pointer;
      }
    }
  }
  .good-info-item {
    background-color: #fff;
    padding: 30px 40px 50px;
    .good-item-list {
      .good-item-tit {
        display: flex;
        align-items: center;
        font-size: 30px;
        color: #666;
        margin-bottom: 35px;
      }
      .good-item-line {
        display: inline-block;
        width: 6px;
        height: 35px;
        background-color: #fa8919;
        margin-right: 15px;
      }
    }
  }
}
// @media screen and (min-width: 320px) and (max-width: 420px)
@media screen and (min-width: 320px) and (max-width: 750px) {
  .page-content {
    width: 100%;
  }
  .good-item-tit {
    font-size: 20px !important;
  }
  .good-info-item {
    padding: 20px !important;
  }
  .good-item-line {
    height: 20px !important;
  }
  .detail-content-box {
    .good-info {
      padding: 0 !important;
      margin-top: -10px !important;
      flex-wrap: wrap !important;
      margin-bottom: 15px !important;
      .carousel-box {
        width: 100% !important;
        height: auto !important;
      }
      .content {
        width: 90% !important;
        margin-top: 0;
        margin-left: 20px !important;
        .itemTitle {
          width: 20% !important;
        }
        .zhiFuItem {
          width: 80%;
        }
      }

      .price-val {
        font-size: 30px !important;
      }
      .shop-cart {
        height: 38px !important;
        font-size: 16px !important;
        margin-bottom: 45px;
        position: relative !important;
      }
      .seckill-cart {
        position: relative !important;
        left: 3px !important;
        height: 36px !important;
        bottom: 18px !important;
        // position: absolute !important;
        // left:  !important;
        .seckill-price {
          line-height: 36px !important;
        }
        .origin {
          line-height: 36px !important;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.itemContent {
  .el-badge__content {
    background-color: #e23b3a;
    border-radius: 12px;
    top: -10px;
  }
  .el-badge__content.is-fixed {
    top: -7px;
    right: -3px;
  }
}
</style>
